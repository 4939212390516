import dateFormat from 'dateformat';
import { addDaysToCurrentDate, getDayOfWeek } from 'site-modules/shared/utils/date-utils';

export function getEspTrustDate() {
  const dayOfWeek = getDayOfWeek();

  const weekShift = dayOfWeek < 4 ? -14 : -7;
  const dayShift = 1 - dayOfWeek;

  const mondayBeforeTwoWednesdays = addDaysToCurrentDate(weekShift + dayShift);

  return dateFormat(mondayBeforeTwoWednesdays, 'mmmm dS');
}

export function getEspTrustDateText() {
  return `Based on nearby sales up through ${getEspTrustDate()}`;
}
