export const LEAD_FORM_EVENTS = {
  DISPLAY: 'leadform.display',
  LIST: 'leadform.list',
  INPUT: 'leadform.input',
  SELECT_STYLE: 'leadform.select-style',
  SELECT_CARD: 'leadform.select-card',
  ATTEMPT_SUBMIT: 'leadform.attempt-submit',
  SUBMIT: 'leadform.submit',
  THANK_YOU: 'leadform.thank-you',
  DISPLAY_THANK_YOU: 'leadform.display-thank-you',
};
