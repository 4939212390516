export const LEAD_FORM_NAMES = {
  NCI: 'NCI',
  EMBEDDED: 'EMBEDDED',
  UCI: 'UCI',
  CONFIGURATOR: 'CONFIGURATOR',
  DIGITAL_RETAIL_OFFER_TYPE: 'DIGITAL_RETAIL_OFFER_TYPE',
  MULTI_DIGITAL_RETAIL_OFFER_TYPE: 'MULTI_DIGITAL_RETAIL_OFFER_TYPE',
  MULTI_DIGITAL_RETAIL_OFFER_TYPE_SRP_NEW: 'MULTI_DIGITAL_RETAIL_OFFER_TYPE_SRP_NEW',
  MULTI_DIGITAL_RETAIL_OFFER_TYPE_SRP_USED: 'MULTI_DIGITAL_RETAIL_OFFER_TYPE_SRP_USED',
  NCI_SRP: 'NCI_SRP',
};
