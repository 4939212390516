import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import classnames from 'classnames';
import { noop } from 'lodash';

import { DrawerOnDemand } from 'site-modules/shared/components/drawer/drawer-on-demand';
import { DrawerCloseButton } from 'site-modules/shared/components/drawer/drawer-close-button/drawer-close-button';
import { LEAD_FORM_NAMES } from 'site-modules/shared/constants/lead-form/lead-form-names';

import './lead-form-drawer.scss';

/**
 * Renders lead form component or iframe with lead form.
 * @param {string} url, this is usually base/multi url of the legacy lead form, that should be passed to ULF under AB test
 * @param {func} [LeadFormComponent]
 * @param {object} [componentData]
 * @param {string} [className]
 * @returns {JSX.Element}
 */
export function LeadFormDrawerComponent({ url, component: LeadFormComponent, componentData, className, fluid }) {
  return LeadFormComponent ? (
    <Container className={className} fluid={fluid}>
      <LeadFormComponent {...componentData} />
    </Container>
  ) : (
    <div className="text-center">
      <iframe className="lead-form-drawer-iframe border-0 w-100" title="Lead Form" scrolling="no" src={url} />
    </div>
  );
}

LeadFormDrawerComponent.propTypes = {
  url: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  componentData: PropTypes.shape({}),
  className: PropTypes.string,
  fluid: PropTypes.bool,
};

LeadFormDrawerComponent.defaultProps = {
  url: '',
  component: null,
  componentData: {},
  className: '',
  fluid: false,
};

/**
 * Renders lead form drawer with iframe or component.
 */
export class LeadFormDrawer extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isOpen && !prevState.isLeadFormComponentLoaded) {
      return {
        isLeadFormComponentLoaded: true,
      };
    }

    return null;
  }

  state = {
    isLeadFormComponentLoaded: false,
  };

  render() {
    const {
      drawerComponent: DrawerComponent,
      className,
      containerClassName,
      drawerContentClassName,
      drawerContentWrapperClassName,
      isLFComponentContainerFluid,
      dataTrackingParent,
      url,
      isOpen,
      closeBtnComponent: CloseButtonComponent,
      component,
      componentData,
      shouldRemoveOnClose,
      size,
      side,
      closeBtnIconClass,
      closeLgBtnIconClass,
      smBtnClass,
      lgBtnClass,
      onHide,
      btnText,
      btnPadding,
      onDrawerToggle,
    } = this.props;
    const { isLeadFormComponentLoaded } = this.state;
    // to set action_category to user in tracking
    const mergedComponentData = {
      ...componentData,
      options: {
        ...componentData.options,
        isUserOpened: true,
      },
      leadFormProps: { ...componentData.leadFormProps, closeDrawer: onDrawerToggle },
    };
    const isMultiDigitalRetailOfferType =
      mergedComponentData.formName === LEAD_FORM_NAMES.MULTI_DIGITAL_RETAIL_OFFER_TYPE;
    const componentContainerClassName =
      containerClassName ||
      classnames({
        'mt-md-1_5': !isMultiDigitalRetailOfferType,
        'w-100 px-0': isMultiDigitalRetailOfferType,
      });

    return (
      <DrawerComponent
        isOpen={isOpen}
        shouldRemoveOnClose={shouldRemoveOnClose}
        unmountOnExit={shouldRemoveOnClose}
        side={side}
        size={size}
        toggle={onDrawerToggle}
        classNames={{
          drawer: classnames('lead-form-drawer', className, {
            'digital-retail-lead-form': isMultiDigitalRetailOfferType,
          }),
          drawerContent: drawerContentClassName,
        }}
        data-tracking-parent={dataTrackingParent}
        onHide={onHide}
        ariaLabel="Contact dealers modal"
      >
        <div
          className={classnames(drawerContentWrapperClassName, {
            'px-0_5 px-md-1': !isMultiDigitalRetailOfferType && !drawerContentWrapperClassName,
          })}
        >
          {CloseButtonComponent ? (
            <CloseButtonComponent onClick={onDrawerToggle} />
          ) : (
            <DrawerCloseButton
              onClick={onDrawerToggle}
              className={classnames({
                'dr-drawer-close': isMultiDigitalRetailOfferType,
              })}
              iconClass={closeBtnIconClass}
              lgIconClass={closeLgBtnIconClass}
              smBtnClass={smBtnClass}
              lgBtnClass={lgBtnClass}
              btnText={btnText}
              btnPadding={btnPadding}
            />
          )}
          {isLeadFormComponentLoaded && (
            <LeadFormDrawerComponent
              url={url}
              component={component}
              componentData={{
                ...mergedComponentData,
                toggleDrawer: onDrawerToggle,
              }}
              className={componentContainerClassName}
              fluid={isLFComponentContainerFluid}
            />
          )}
        </div>
      </DrawerComponent>
    );
  }
}

LeadFormDrawer.propTypes = {
  url: PropTypes.string.isRequired,
  drawerComponent: PropTypes.elementType,
  isOpen: PropTypes.bool.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  drawerContentClassName: PropTypes.string,
  drawerContentWrapperClassName: PropTypes.string,
  closeBtnComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  componentData: PropTypes.shape({}),
  size: PropTypes.string,
  dataTrackingParent: PropTypes.string,
  shouldRemoveOnClose: PropTypes.bool,
  side: PropTypes.string,
  closeBtnIconClass: PropTypes.string,
  closeLgBtnIconClass: PropTypes.string,
  smBtnClass: PropTypes.string,
  lgBtnClass: PropTypes.string,
  btnText: PropTypes.string,
  btnPadding: PropTypes.string,
  onHide: PropTypes.func,
  isLFComponentContainerFluid: PropTypes.bool,
};

LeadFormDrawer.defaultProps = {
  drawerComponent: DrawerOnDemand,
  className: null,
  containerClassName: null,
  drawerContentClassName: null,
  drawerContentWrapperClassName: null,
  closeBtnComponent: null,
  component: null,
  componentData: {},
  size: 'medium',
  dataTrackingParent: 'lead-form-request-price-offer-drawer',
  shouldRemoveOnClose: true,
  side: 'right',
  closeBtnIconClass: 'icon-cross2',
  closeLgBtnIconClass: 'circle icon-arrow-left3 bg-gray-lighter',
  smBtnClass: '',
  lgBtnClass: '',
  btnPadding: 'pt-1',
  btnText: 'back',
  onHide: noop,
  isLFComponentContainerFluid: false,
};
