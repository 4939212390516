export const LEAD_TYPES = {
  INVENTORY: 'inventory',
  DEALER: 'dealer',
  ANY: 'any',
};

export const CARD_VIEW = {
  INVENTORY: 'inventory',
  DEALER: 'dealer',
  DEALER_SIMPLE: 'dealer_simple',
  DEALER_PRICE: 'dealer_price',
  DEALER_LIST: 'dealer_list',
  DEALER_VIN: 'dealer_vin',
  DEFAULT: 'default',
  DIGITAL_RETAIL_OFFER_TYPE_TABLE: 'digital_retail_offer_type_table',
  DEALER_VIN_CHECK_AVAILABILITY: 'dealer_vin_check_availability',
  VDP_PRICING_CHART: 'vdp_pricing_chart',
  NEW_BASIC: 'new_basic',
  DEALER_VIN_SRP_NEW: 'dealer_vin_srp_new',
  DEALER_VIN_SRP_USED: 'dealer_vin_srp_used',
  NCI_SRP: 'nci_srp',
};

export const LEAD_PRODUCT_TYPES = {
  usedInventory: 'UsedInventory',
  newInventory: 'NewInventory',
};

export const HEADER_COMPONENT = {
  BASE: 'base',
  EMBEDDED: 'embedded',
  VDP_EMBEDDED: 'vdpEmbedded',
  PRICE_CHECKER: 'priceChecker',
  PHOTOFLIPPER_VDP: 'photoflipperVdp',
  CONVEX: 'convex',
};

export const SUB_HEADER_COMPONENT = {
  BASE: 'base',
};

export const LEAD_FORM_VIEWS = {
  BASE: 'base',
  MAD_LIBS: 'madLibs',
  DIGITAL_RETAIL_OFFER_TYPE: 'digitalRetailOfferType',
  MULTI_DIGITAL_RETAIL_OFFER_TYPE: 'multiDigitalRetailOfferType',
  MULTI_DIGITAL_RETAIL_OFFER_TYPE_SRP_NEW: 'multiDigitalRetailOfferTypeSrpNew',
  MULTI_DIGITAL_RETAIL_OFFER_TYPE_SRP_USED: 'multiDigitalRetailOfferTypeSrpUsed',
  TOP_EMBEDDED_TEXT: 'topEmbeddedText',
  TOP_EMBEDDED: 'topEmbedded',
  PHOTOFLIPPER: 'photoflipper',
  MODULAR: 'modular',
  NCI_SRP: 'nciSrp',
};

export const LOADING_COMPONENT = {
  CONVEX: 'convex',
};

export const THANK_YOU_VIEWS = {
  BASE: 'base',
  TRANSPARENT_PRICING_PREPROD: 'transparentPricingPreprod',
  TRANSPARENT_PRICING_PRICE_CHECKER: 'transparentPricingPriceChecker',
  MULTI_DIGITAL_RETAIL_OFFER_TYPE: 'multiDigitalRetailOfferType',
  DEAL_CHECK_AVAILABILITY: 'dealCheckAvailability',
  SRP_PRICE_CHECKER: 'srpPriceChecker',
};

export const THANK_YOU_AD_COMPONENT = {
  BASE: 'base',
};

export const USER_INFO_POSITION = {
  BEFORE_CARDS: 'beforeCards',
  AFTER_CARDS: 'afterCards',
};

export const COLLAPSE_STATE = {
  PARTIAL_COLLAPSE: 'partial',
};
