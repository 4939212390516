import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Drawer, FADE_ANIMATION } from 'site-modules/shared/components/drawer/drawer';

/**
 * DrawerOnDemand only renders when user opens drawer and will be destroyed on close action.
 * If you want to save state at closing, need to use flag shouldRemoveOnClose = false
 * Component should be always closed by default, isOpen = false
 */
export class DrawerOnDemand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isRender: false,
    };
  }

  static getDerivedStateFromProps(props, prevState) {
    if (props.isOpen && !prevState.isOpen) {
      return {
        isRender: true,
      };
    } else if (!props.isOpen && prevState.isOpen) {
      return {
        isOpen: false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { isOpen, shouldRemoveOnClose } = this.props;
    if (isOpen !== prevProps.isOpen) {
      if (isOpen) {
        this.openDrawer();
      } else if (shouldRemoveOnClose) {
        // remove drawer after 300ms, it is animation duration
        setTimeout(this.closeDrawer, FADE_ANIMATION);
      }
    }
  }

  openDrawer = () => {
    this.setState({ isOpen: true });
  };

  closeDrawer = () => {
    this.setState({ isRender: false });
  };

  render() {
    const { drawerRef, ...resProps } = this.props;
    if (this.state.isRender) {
      return <Drawer {...resProps} isOpen={this.state.isOpen} ref={drawerRef} />;
    }
    return null;
  }
}

DrawerOnDemand.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  shouldRemoveOnClose: PropTypes.bool,
  drawerRef: PropTypes.shape({}),
};

DrawerOnDemand.defaultProps = {
  shouldRemoveOnClose: true,
  drawerRef: null,
};
