import { get, isNumber } from 'lodash';
import getOr from 'lodash/fp/getOr';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { LEAD_TYPES, CARD_VIEW } from 'site-modules/shared/constants/lead-form/lead-form-params';
import { EventToolbox } from 'client/utils/event-toolbox';

import { getS3MediaProfileImageUrlBySize } from 'client/utils/image-helpers';
import { isCarMax } from 'site-modules/shared/utils/dealer-details-utils';
import { DATA_PATH } from 'client/engagement-handlers/inventory-engagement-handler/constants';
import { LEAD_FORM_EVENTS } from 'site-modules/shared/constants/lead-form/lead-form-events';
import { TrackingConstant } from 'client/tracking/constant';
import { INVENTORY_TYPES } from 'client/constants/inventory-types';
import { getNoVehicleImageToDisplay } from 'site-modules/shared/utils/get-no-vehicle-image-to-display';
import { getPublicationStateForMakeModel } from 'client/site-modules/shared/utils/publication-states';
import { clearPhone } from 'site-modules/shared/utils/phone-format';

export function getLeadType(leadTarget) {
  if (leadTarget.inventory) {
    return LEAD_TYPES.INVENTORY;
  } else if (leadTarget.dealer) {
    return LEAD_TYPES.DEALER;
  }
  return null;
}

export function getCardView(leadTarget) {
  if (leadTarget.inventory) {
    return CARD_VIEW.INVENTORY;
  } else if (leadTarget.dealer) {
    return CARD_VIEW.DEALER;
  }
  return null;
}

export function getLeadUid(leadTarget) {
  return get(
    leadTarget,
    'inventory.vin',
    get(leadTarget, '.dealer.id', get(leadTarget, 'dealer.uniqueKey', get(leadTarget, 'dealer.rooftopId')))
  );
}

/**
 * Return vehicle status from leadTarget
 * @param leadTarget
 * @returns {string}
 */
export function getVehicleStatus(leadTarget) {
  /*
     If we have an "inventory" lead, then we know if it is a NEW, USED or CPO VIN.  We get this value
     from leadTarget.inventory.type.

     If we have a "dealer" lead, then we use NEW or USED designation from the dealer rooftop.  We get this value
     from leadTarget.dealer.newUsedType.  However, at the time of writing this comment, we should not be receiving
     any "dealer" leads from USED rooftops (ie. we only have NEW dealer locator leads).  For this reason, if
     all of these fields end up being empty, we are returning a default value of 'NEW' (ie. INVENTORY_TYPES.NEW).
  */

  return get(leadTarget, 'inventory.type', get(leadTarget, 'dealer.newUsedType', INVENTORY_TYPES.NEW)).toUpperCase();
}

export function getLeadDealerId(leadTarget) {
  const type = getLeadType(leadTarget);
  const dealer = leadTarget[type].dealerInfo || leadTarget[type];
  return parseInt(
    get(dealer, 'franchiseId', get(dealer, 'id', get(dealer, 'uniqueKey', get(dealer, 'rooftopId'))), 0),
    10
  );
}

export function getRooftopId(leadTarget) {
  const type = getLeadType(leadTarget);
  const dealer = leadTarget[type].dealerInfo || leadTarget[type];
  return parseInt(get(dealer, 'rooftopId', 0), 10);
}

export function getPrimaryStyleId(leadTargets) {
  // this dealer is suggested, style id will be obtained from primary lead inventory
  const primaryLead = leadTargets.find(({ leadAttributes: { primary } }) => primary);
  return parseInt(get(primaryLead, 'inventory.vehicleInfo.styleInfo.styleId', 0), 10);
}

/**
 * Get lead to submission: inventory lead -> { vin: some_value }, dealer lead -> { styleId: some_value }
 * @param leadTarget
 * @param primaryStyleId
 // this style id can be set by user (QQ) or set from page (Core page)
 // if style id is not set, it means dealer is suggested, style id will be obtained from primary lead inventory
 // if primary lead inventory does not exist then will be obtained from current lead target on submission
 * @returns {*}
 */
export function getLeadToSubmit(leadTarget, primaryStyleId) {
  const type = getLeadType(leadTarget);
  if (type === LEAD_TYPES.INVENTORY) {
    return { vin: leadTarget[type].vin };
  }
  if (type === LEAD_TYPES.DEALER && primaryStyleId) {
    return { styleId: parseInt(primaryStyleId, 10) };
  }
  // primaryStyleId is not defined, try to take from leadTarget
  if (type === LEAD_TYPES.DEALER) {
    const styleId = parseInt(get(leadTarget, 'vehicle.styleId', 0), 10);
    return { styleId };
  }
  return {};
}

/**
 * Prepare array of params for template from props
 * @param fromProps
 * @returns {array}
 */
export function getTemplateParams({ leadTargets, make, model, year, submodel, userInfo, customConfig }) {
  const dealerInfoName = get(leadTargets, 'length') === 1 ? get(leadTargets[0], 'inventory.dealerInfo.name') : '';
  const { savings, minBestDealPrice, minDisplayPrice } = (leadTargets || []).reduce(
    (accum, leadTarget) => {
      const displayPrice = get(leadTarget, 'inventory.prices.displayPrice', 0);
      if (displayPrice === 0) {
        // no pricing info available, skip inventory
        return accum;
      }

      const bestDealPrice = get(leadTarget, 'inventory.computedInfo.bestDealPrice', 0);
      const savingsValue = bestDealPrice ? displayPrice - bestDealPrice : 0;

      const {
        savings: prevSavings,
        minBestDealPrice: prevMinBestDealPrice,
        minDisplayPrice: prevMinDisplayPrice,
      } = accum;

      // use the current savings vlue if it is greater then the previous one
      const newSavings = prevSavings < savingsValue ? savingsValue : prevSavings;
      // use the current best deal price value if it is present and lower than previous one
      // or the previous one is absent
      const newMinBestDealPrice =
        (bestDealPrice < prevMinBestDealPrice && bestDealPrice > 0) || prevMinBestDealPrice === 0
          ? bestDealPrice
          : prevMinBestDealPrice;
      const newMinDisplayPrice =
        (displayPrice < prevMinDisplayPrice && displayPrice > 0) || prevMinDisplayPrice === 0
          ? displayPrice
          : prevMinDisplayPrice;

      return { savings: newSavings, minBestDealPrice: newMinBestDealPrice, minDisplayPrice: newMinDisplayPrice };
    },
    { savings: 0, minBestDealPrice: 0, minDisplayPrice: 0 }
  );

  const explainingTextParams = get(customConfig, 'explainingTextParams', []);

  // any value should have zero value if it maybe undefined and has condition
  const params = [
    { name: 'dealerName', value: dealerInfoName },
    { name: 'make', value: make },
    { name: 'model', value: model },
    { name: 'submodel', value: submodel },
    { name: 'year', value: `${year}` },
    { name: 'lead_number', value: `${get(leadTargets, 'length')}` },
    { name: 'firstName', value: get(userInfo, 'firstName') },
    { name: 'lastName', value: get(userInfo, 'lastName') },
    { name: 'savingsValue', value: savings ? formatPriceString(savings) : '0' },
    { name: 'minBestDealPrice', value: minBestDealPrice ? formatPriceString(minBestDealPrice) : '0' },
    { name: 'minDisplayPrice', value: minDisplayPrice ? formatPriceString(minDisplayPrice) : '0' },
  ].concat(explainingTextParams.map(({ name, value }) => ({ name, value: `${value || 0}` })));
  return params;
}

function applyRegExpTemplate(param, template, reg) {
  let result = template;
  while (true) {
    const match = reg.exec(result);
    if (!match) {
      break;
    }
    const replace = (param.value === match[1] ? match[2] : match[3]).replace(/\$/g, '$$$$');
    result = result.replace(reg, replace);
  }
  return result;
}

/**
 * Apply params to template, e.g.
 * 1) templateParams = [{ name: 'year', value: '2018' }, { name: 'make', value: 'Honda'}, { name: 'model', value: 'Accord'} ]
 * template = 'Car {year} {make} {model}'
 * return 'Car 2018 Honda Accord'
 * 2)  templateParams = [{ name: 'lead_number', value: 1 } ]
 * template = 'You have {lead_number=1?:{lead_number}} special {lead_number=1?offer:offers'}
 * return 'You have special offers'
 * 3) templateParams = [{ name: 'lead_number', value: 3 } ]
 * template = 'You have {lead_number=1?:{lead_number}} special {lead_number=1?offer:offers'}
 * return 'You have 3 special offers'
 * 4) templateParams = [{ name: 'minBestDealPrice', value: '$15,435' }, { name: 'trim', value: 'LX' }, { name: 'style', value: '0' } ]
 * template = '<<minBestDealPrice=0?Success!:The Edmunds Suggested Price {minBestDealPrice} for [style=0?{trim}:{style}]>>
 * return 'The Edmunds Suggested Price $15,435 for LX'
 * @param templateParams {array} array of objects { name, value }
 * @param templateIn {string}
 * @returns {string}
 */
export function applyTemplateParams(templateParams, templateIn) {
  return templateIn && templateParams.length
    ? templateParams.reduce((templateOut, param) => {
        const regParam = new RegExp(`{${param.name}}`, 'gm');
        let template = templateOut.replace(regParam, param.value);
        // for templates using complex multiple conditions
        template = applyRegExpTemplate(
          param,
          template,
          new RegExp(`\\<\\<${param.name}=(\\d+)\\?([\\s\\S]*?):([\\s\\S]*?)\\>\\>`)
        );
        template = applyRegExpTemplate(
          param,
          template,
          new RegExp(`\\[${param.name}=(\\d+)\\?([\\s\\S]*?):([\\s\\S]*?)\\]`)
        );
        template = applyRegExpTemplate(
          param,
          template,
          new RegExp(`\\{${param.name}=(\\d+)\\?([\\s\\S]*?):([\\s\\S]*?)\\}`)
        );
        return template;
      }, templateIn)
    : templateIn;
}

export function generateCertificateId(vin, userInfo) {
  const { firstName, lastName, email } = userInfo;
  const date = new Date();
  return (
    vin.substr(-4) +
    firstName.substr(0, 1) +
    lastName.substr(0, 1) +
    email.substr(0, 2) +
    (date.getMonth() + 1) +
    date.getDate()
  ).toUpperCase();
}

export function getVehiclePhoto(vehicleInfo, showMakeStockImage) {
  const bodyType = get(vehicleInfo, 'styleInfo.bodyType', '');
  let photoUrl = get(vehicleInfo, 'photo.defaultPhoto.large.url');
  if (!photoUrl && showMakeStockImage) {
    const {
      styleInfo: { year, make, model },
    } = vehicleInfo;
    photoUrl = getS3MediaProfileImageUrlBySize(
      {
        make: { slug: makeNiceName(make) },
        model: { slug: makeNiceName(model) },
        year,
      },
      256
    );
  }
  photoUrl = photoUrl || getNoVehicleImageToDisplay(bodyType);
  return photoUrl;
}

const propList = [
  ['make', 'make.name'],
  ['model', 'model.name'],
  ['year', 'year'],
  ['submodel', 'submodels.name'],
  ['submodelIdentifier', 'submodels.slug'],
];

export const getVehicleProps = (list = propList) => data =>
  list.reduce((props, prop) => {
    // eslint-disable-next-line no-param-reassign
    props[prop[0]] = get(data, prop[1]);
    return props;
  }, {});

export const getPathname = getOr('', 'pathname');

export function getUserInfoFromProfile(profile) {
  const firstName = get(profile, 'person.firstName');
  const lastName = get(profile, 'pii.lastName');
  const email = get(profile, 'identifiers.email[0]');
  // get uid from #/leads/0bb6b4df-45f4-4690-bc7c-a4ad5400eff5
  const lastLeadReference = get(profile, 'lastLeadReference', '');
  const lastLead = lastLeadReference.substring(lastLeadReference.lastIndexOf('/') + 1);
  // get primary phone otherwise from last lead submission
  const phone =
    get(profile, `pii.phoneNumbers[0]`) || get(profile, `pii.contactInfo.['${lastLead}'].phoneNumbers[0]`, {});

  const { areaCode, number } = phone;
  const phoneNumber = areaCode && number && `(${areaCode}) ${number}`;
  return {
    firstName,
    lastName,
    email,
    phoneNumber,
  };
}

/**
 * Returns info for lead submission.
 * @param {object[]} leads
 * @param {string} creativeId
 * @param {string} pathname
 * @param {object[]} leadTargets
 * @param {string} visitorId
 * @param {string} sessionId
 * @param {string} tags
 * @param {object} featureFlags
 * @param {string} zipCode
 * @param {object} phone
 * @param {string} email
 * @param {string} firstName
 * @param {string} lastName
 * @returns {object}
 */
export function getLeadSubmissionInfo({
  leads,
  creativeId,
  pathname,
  leadTargets,
  visitorId,
  sessionId,
  tags,
  featureFlags,
  zipCode,
  phone,
  email,
  firstName,
  lastName,
}) {
  return {
    leads,
    submissionInfo: {
      creativeId,
      bannerCode: Date.now(),
      referringUrl: `https://www.edmunds.com${pathname}`,
      totalLeadsDisplayed: leadTargets.length,
      sessionId,
      visitorId,
      featureFlags,
      tags,
    },
    userInfo: {
      zipCode,
      areaCode: phone.slice(0, 3),
      phonePrefix: phone.slice(3, 6),
      phoneSuffix: phone.slice(6, 10),
      email,
      firstName,
      lastName,
    },
  };
}

// TODO: Remove when marketo email is triggered on backend
export function getLeadSubmissionInfoWithoutEmail(params) {
  const leadSubmissionInfo = getLeadSubmissionInfo(params);

  return {
    ...leadSubmissionInfo,
    submissionInfo: {
      ...leadSubmissionInfo.submissionInfo,
      skipEmail: true,
    },
  };
}

function getCarmaxTransferMessage(code) {
  return code === 'paid' || code === 'free'
    ? 'Edmunds.com shopper potentially interested in a transfer               \n\n' // weird syntax requested for test
    : '';
}

const DELIVERY_TYPES = {
  paid: 'p', // CarMax paid delivery vehicle
  free: 'f', // CarMax free delivery vehicle
  local: 'l', // CarMax local vehicle (available to be delivered)
  nondeliverable: 'n', // CarMax non-deliverable vehicle
};

export function getCarMaxComment(targetInventory) {
  const parentDealershipName = get(targetInventory, DATA_PATH.PARENT_DEALERSHIP_NAME, '');
  let comment = '';

  if (isCarMax(parentDealershipName)) {
    const distance = get(targetInventory, DATA_PATH.DEALER_DISTANCE);
    const code = get(targetInventory, DATA_PATH.DELIVERY_CODE, '').toLowerCase();
    const dealerDistance = isNumber(distance) ? distance.toFixed(0) : 0;
    const transferMessage = getCarmaxTransferMessage(code);
    const type = code ? DELIVERY_TYPES[code] : DELIVERY_TYPES.nondeliverable;

    comment = `${transferMessage}##${type}${dealerDistance}##`;
  }

  return comment;
}

/**
 * Fires tracking events for thank you view.
 * @param {object[]} leadTargets
 * @param {object[]} leads
 * @param {object} trackingData
 * @param {HTMLElement} target
 */
export function fireThankYouTracking({ leadTargets, trackingData, target }) {
  EventToolbox.fireCustomEvent(
    LEAD_FORM_EVENTS.LIST,
    { leadTargets, trackingData: { ...trackingData, subactionName: TrackingConstant.SUBMIT } },
    target
  );
  EventToolbox.fireCustomEvent(LEAD_FORM_EVENTS.THANK_YOU, { leadTargets }, target);
}

export function getLeadTargetInfo(leadTarget) {
  const dealerInfo = get(leadTarget, 'inventory.dealerInfo') || get(leadTarget, 'dealer');
  const vehicleInfo = get(leadTarget, 'inventory.vehicleInfo');

  return {
    styleInfo: get(vehicleInfo, 'styleInfo') || get(leadTarget, 'vehicle'),
    vehicleColors: get(vehicleInfo, 'vehicleColors'),
    partsInfo: get(vehicleInfo, 'partsInfo'),
    dealerInfo: {
      ...dealerInfo,
      franchiseId: (get(dealerInfo, 'franchiseId', '') || get(dealerInfo, 'id', '')).toString(), // dealerInfo.id is same as franchise id
    },
  };
}

/**
 * Returns info for lead submission.
 * @param {object} dealer
 * @param {object} vehicle
 * @param {string} creativeId
 * @param {string} pathname
 * @param {string} visitorId
 * @param {string} sessionId
 * @param {string} zipCode
 * @param {string} phone
 * @param {string} email
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} comments
 * @param {string} exteriorColor
 * @param {array} tags
 * @returns {object}
 */
export function getCsdLeadSubmissionInfo({
  dealer,
  vehicle,
  creativeId,
  pathname,
  visitorId,
  sessionId,
  zipCode,
  phoneNumber,
  email,
  firstName,
  lastName,
  comments,
  exteriorColor,
  tags,
}) {
  const phone = clearPhone(phoneNumber);
  return {
    testLead: firstName === 'SendTestLead' || lastName === 'SendTestLead',
    sendToPartnerQA: false,
    vehicleInquiries: [
      {
        comments,
        rooftopId: get(dealer, 'dealerLocationId', get(dealer, 'rooftopId')),
        vehicleStatus: getPublicationStateForMakeModel(get(vehicle, 'pubStates')).toUpperCase(),
        styleId: get(vehicle, 'styleId'),
        make: get(vehicle, 'make.name'),
        model: get(vehicle, 'model.name'),
        trim: get(vehicle, 'trim.trimName', ''),
        year: Number(get(vehicle, 'year')),
        exteriorColor,
      },
    ],
    submissionInfo: {
      referringUrl: `https://www.edmunds.com${pathname}`,
      sessionId,
      visitorId,
      tags,
      creativeId,
    },
    userInfo: {
      zipCode,
      areaCode: phone.slice(0, 3),
      phonePrefix: phone.slice(3, 6),
      phoneSuffix: phone.slice(6, 10),
      email,
      firstName,
      lastName,
    },
  };
}

export function getTopDealerComments(vehicle, additionalData = {}, isPartialPaywallDealer = false) {
  const trim = get(additionalData, 'trim');
  const color = get(additionalData, 'color');
  const make = get(vehicle, 'make.name', '');
  const model = get(vehicle, 'model.name', '');
  const year = get(vehicle, 'year');

  return `This shopper was referred to you by Edmunds${
    isPartialPaywallDealer
      ? ''
      : `, who featured your dealer as one of the most competitively priced for the ${model} in your region`
  }. Please note this user's vehicle preferences:

  ${year} ${make} ${model}

  Preferred Trim: ${trim || 'None Provided'}
  Preferred Color: ${color || 'None Provided'}`;
}
